<template>
  <div>
    <el-container>
      <!--
        头部区域
      -->
      <el-header height="60px">
        <el-menu
          default-active="systemTitle"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b">
          <el-menu-item index="systemTitle" class="sys-title">后台管理系统</el-menu-item>
          <el-submenu index="personInfo" class="sys-setting">
            <template slot="title">欢迎您&nbsp;{{realName}}</template>
            <el-menu-item index="logout">退出</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-header>
      <el-container>
        <!--
        侧边区域
        -->
        <el-aside width="220px">
          <el-menu
            @select="asideMenuSelect"
            :default-active="defaultActive"
            class="el-menu-vertical-demo"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b">
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-setting"></i>
                <span>系统管理</span>
              </template>
              <el-menu-item index="user">
                <span slot="title">用户管理</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="category">
              <i class="el-icon-takeaway-box"></i>
              <span slot="title">分类管理</span>
            </el-menu-item>
            <el-menu-item index="course">
               <i class="el-icon-video-camera"></i>
              <span slot="title">课程管理</span>
            </el-menu-item>
            <el-menu-item index="chapter">
              <i class="el-icon-reading"></i>
              <span slot="title">章节管理</span>
            </el-menu-item>
            <el-menu-item index="class_hour">
              <i class="el-icon-time"></i>
              <span slot="title">课时管理</span>
            </el-menu-item>
            <el-menu-item index="article">
              <i class="el-icon-document"></i>
              <span slot="title">文章管理</span>
            </el-menu-item>
<!--            <el-menu-item index="toolManual">
              <i class="el-icon-s-tools"></i>
              <span slot="title">工具手册</span>
            </el-menu-item>-->
          </el-menu>
        </el-aside>
        <el-container>
          <!--
            主要内容区域
          -->
          <el-main>
            <router-view></router-view>
          </el-main>
          <el-footer>版权所有 @copy 翻版必究</el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data(){
    return {
        defaultActive:"category"
    }
  },
    computed:{
      realName(){
       return this.$store.state.Login.userName || "未登录"
      }
    },
    methods:{
      logout(){
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("userInfo")
        this.$store.state.Login.userName = ""
        this.$router.replace({name:"login_new"})
      },
      asideMenuSelect(val){
        // console.log(val)
        this.defaultActive = val
        this.$router.push({name:val}).catch((e)=>{

        })
      },
      handleSelect(val){
        if (val == "logout"){
          this.logout()
        }
      }
    },
    mounted() {
      let urlArr = location.href.split(/\//)
      this.defaultActive = urlArr[urlArr.length-1]
    }}
</script>

<style scoped>
.el-container{
  height: 93vh;
}
.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-header{
  padding: 0px;
}
.sys-title{
  font-size: 24px;
  font-weight: bold;
  width: 220px;
}
.sys-setting{
  float:right;
}

.el-aside {
  background-color: #545c64;
}
.el-main {
  background-color: #E9EEF3;
}
</style>
